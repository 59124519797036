import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import './aboutme.css';
import { Link } from 'react-router-dom'; 
import Sidebar from '../../Sidebar';
  
function Aboutme() {
    const [items, setItems] = useState([]);


  useEffect(() => {
    axios.get('/backend/home.php', {
      headers: {
          'Custom-type': 'about',
          'Content-Type': 'application/json',  // Beispiel für Content-Type
          // Weitere Header können hier hinzugefügt werden
      }
  })
  .then(response => {
      setItems(response.data);
  })
  .catch(error => {
      console.error('Es gab einen Fehler beim Abrufen der Daten:', error);
  });
  
  }
  , []);// Leer, d.h. dieser Effekt wird nur einmal nach dem initialen Rendern ausgeführt

  useEffect(() => {
      LanguageChange();
    }, [items]);// Dieser Effekt wird nur ausgeführt, wenn sich `items` ändert
  
    const LanguageChange = () => {
      if (items.length > 0) {
        const languageIndex = sessionStorage.getItem("language_index");
        const isEnglish = languageIndex != null && languageIndex == 1;
        document.getElementById('prog-topic').innerText= isEnglish ? "Programming language knowledge:":"Programmiersprachen Kenntnisse:";
        document.getElementById('frame-topic').innerText= isEnglish ? "Framework knowledge:":"Framework Kenntnisse:";
        document.getElementById('tools-topic').innerText= isEnglish ? "Development environments & tools:":"Entwicklungsumgebungen & Tools:";
        const texts = ["text1"];
        const topics = ["topic1"];

        texts.forEach((id, index) => {
          const element = document.getElementById(id);
          const topic_element = document.getElementById(topics[index]);
          if (element && items[index]) {
            element.innerHTML = isEnglish ? items[index].en_text : items[index].de_text;
          }
          if(topic_element){
              topic_element.innerHTML = isEnglish ? items[index].en_topic : items[index].de_topic;
          }
        });
      } else {
        //document.getElementById('text1').innerHTML = "Error";
      }
    };
  return (
    <div><Sidebar onLanguageChange={LanguageChange}/> 
    <div class="App-header">
        <div class="img_header"><img src="/images/header_1.jpg"></img></div>
      <div class="text_container" id="service-container">
      <div class="text_container_data">
        <div class="about-me">
          <div class="about-me-topbar">
          <div class="tiles">
          <div class='tileinfo' id='prog-topic'>Programmiersprachen Kenntnisse:</div>
          <div class="tile" id='tile1'>C#</div>
          <div class="tile" id='tile2'>Javascript</div>
          <div class="tile" id='tile3'>Python</div>
          <div class="tile" id='tile4'>Java</div>
          <div class="tile" id='tile10'>C</div>
          <div class="tile" id='tile6'>Swift</div>
          <div class="tile" id='tile7'>Applescript</div>
          <div class="tile" id='tile8'>HTML</div>
          <div class="tile" id='tile9'>PHP</div>
          <div class="tile" id='tile5'>C++</div>
          <div class="tile" id='tile11'>Dart</div>

        </div>
            <div class="aboutme-photo"><img src="/images/self.jpg"></img></div>
            <div class="tiles" id='right'>
            <div class='tileinfo' id='frame-topic'>Framework Kenntnisse:</div>
          
          <div class="tile" id='tile7'>.NET</div>
          <div class="tile" id='tile6'>Django</div>
          
          <div class="tile" id='tile3'>Unity</div>
          <div class="tile" id='tile4'>React</div>
          <div class="tile" id='tile9'>Flutter</div>
          <div class='tileinfo' id='tools-topic'>Entwicklungsumgebungen & Tools: </div>
          <div class="tile" id='tile2'>Visual Studio</div>
          <div class="tile" id='tile11'>Figma</div>
          <div class="tile" id='tile5'>Xcode</div>
          <div class="tile" id='tile8'>Android Studio</div>
          
        </div>
            </div>
        
        <h1 id='topic1'>Über Mich</h1>
       
        <div class="aboutme-text" id='text1'>
        <a>Ich bin Finn, Web- und App-Entwickler mit über sechs Jahren Erfahrung in der Entwicklung von mobilen Anwendungen für iOS und Android. Bereits während meines Informatikstudiums habe ich als Softwareentwickler gearbeitet, mit einem Schwerpunkt auf der Entwicklung von Anwendungen für Windows und macOS. Parallel dazu habe ich stets mobile Apps für iOS und Android weiterentwickelt. Nach meinem Abschluss an der Leibniz Universität Hannover habe ich mich im Bereich Software- und Webentwicklung selbstständig gemacht.
        </a>
        </div>
        <div class="phonetiles">
        <div class="tile" id='tileinfo'>Programmiersprachen Kenntnisse:</div>
          <div class="tile" id='tile1'>C#</div>
          <div class="tile" id='tile2'>Javascript</div>
          <div class="tile" id='tile3'>Python</div>
          <div class="tile" id='tile4'>Java</div>
          <div class="tile" id='tile10'>C</div>
          <div class="tile" id='tile6'>Swift</div>
          <div class="tile" id='tile7'>Applescript</div>
          <div class="tile" id='tile8'>HTML</div>
          <div class="tile" id='tile9'>PHP</div>
          <div class="tile" id='tile5'>C++</div>
          <div class="tile" id='tile11'>Dart</div>
            <div class="tile" id='tileinfo'>Framework Kenntnisse:</div>
          
          <div class="tile" id='tile7'>.NET</div>
          <div class="tile" id='tile6'>Django</div>
          
          <div class="tile" id='tile3'>Unity</div>
          <div class="tile" id='tile4'>React</div>
          <div class="tile" id='tile9'>Flutter</div>
          <div class="tile" id='tileinfo'>Entwicklungsumgebungen & Tools: </div>
          <div class="tile" id='tile2'>Visual Studio</div>
          <div class="tile" id='tile11'>Figma</div>
          <div class="tile" id='tile5'>Xcode</div>
          <div class="tile" id='tile8'>Android Studio</div>
          
        </div>
        </div>


      </div>
      </div>
    </div>
    </div>
  );
}

export default Aboutme;