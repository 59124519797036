import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import './services.css';
import { Link } from 'react-router-dom'; 
import Sidebar from '../../Sidebar';
  


function Phone_app(){
    const [items, setItems] = useState([]);

    useEffect(() => {
      axios.get('/backend/home.php', {
        headers: {
            'Custom-type': 'service3',
            'Content-Type': 'application/json',  // Beispiel für Content-Type
            // Weitere Header können hier hinzugefügt werden
        }
    })
    .then(response => {
        setItems(response.data);
    })
    .catch(error => {
        console.error('Es gab einen Fehler beim Abrufen der Daten:', error);
    });
    
    }
    , []);// Leer, d.h. dieser Effekt wird nur einmal nach dem initialen Rendern ausgeführt

    useEffect(() => {
        LanguageChange();
      }, [items]);// Dieser Effekt wird nur ausgeführt, wenn sich `items` ändert
    
      const LanguageChange = () => {
        if (items.length > 0) {
          const languageIndex = sessionStorage.getItem("language_index");
          const isEnglish = languageIndex != null && languageIndex == 1;
      
          const texts = ["text1"];
          const topics = ["", "e_topic1"];
          document.getElementById('topic_header').innerText= isEnglish ? "Mobile applications" : "Mobile Anwendungen";
          document.getElementById('text_header').innerText=isEnglish ? "We offer customized mobile applications that meet your individual requirements and realize your digital goals":"Wir bieten maßgeschneiderte mobile Anwendungen an, die Ihren individuellen Anforderungen entsprechen und Ihre digitalen Ziele verwirklichen";
          
          document.getElementById('contact_1').innerText= isEnglish ? "For further information" : "Für weitere Informationen";
          document.getElementById('link').innerText= isEnglish ? "contact me" : "Kontaktieren Sie mich";
          
          texts.forEach((id, index) => {
            const element = document.getElementById(id);
            const topic_element = document.getElementById(topics[index]);
            if (element && items[index]) {
              element.innerHTML = isEnglish ? items[index].en_text : items[index].de_text;
            }
            if(topic_element){
                topic_element.innerHTML = isEnglish ? items[index].en_topic : items[index].de_topic;
            }
          });
        } else {
          document.getElementById('text1').innerHTML = "Loading...";
        }
      };
    return(
        <div><Sidebar onLanguageChange={LanguageChange}/>
                <div class="App-header">
            <div class="img_header">
                <h1 id='topic_header'></h1>
                <p id='text_header'>Wir bieten maßgeschneiderte mobile Anwendungen an, die Ihren individuellen Anforderungen entsprechen und Ihre digitalen Ziele verwirklichen</p>
                <img src="/images/web_app_header.jpg"></img></div>
            <div class="text_container" id="service-container">
            <div class="text_container_data">
            <a id="text1">Loading...</a>
           <div class="contact_line"> <p id='contact_1'>Für weitere Informationen </p> <Link to="/contact" id="link" >Kontaktieren Sie mich</Link></div>
                </div>
                </div>
            </div>
        </div>

    );

}

export default Phone_app;