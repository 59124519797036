import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import './frontpage.css';
import Sidebar from '../../Sidebar';
import { Link } from 'react-router-dom'; 

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}

function Frontpage() {
  const [items, setItems] = useState([]);
  const csrftoken = getCookie('csrftoken');

  useEffect(() => {
    axios.get('/backend/home.php', {
        headers: {
            'Custom-type': 'home',
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
        }
    })
    .then(response => {
        setItems(response.data);
    })
    .catch(error => {
        console.error('Es gab einen Fehler beim Abrufen der Daten:', error);
    });
}, []);

  useEffect(() => {
    LanguageChange();
  }, [items]);// Dieser Effekt wird nur ausgeführt, wenn sich `items` ändert

  const LanguageChange = () => {
    if (items.length > 0) {
      const languageIndex = sessionStorage.getItem("language_index");
      const isEnglish = languageIndex != null && languageIndex == 1;
  
      const texts = ["text1", "e_text1", "e_text2", "e_text3"];
      const topics = ["", "e_topic1", "e_topic2", "e_topic3"];
     document.getElementById('topic_header').innerText="FM-Software";
     document.getElementById('first_header').innerText=isEnglish ?"Home":"Startseite";
     document.getElementsByName('show-more').forEach((element)=>{
      element.innerText=isEnglish ? "Show more":"Mehr anzeigen"
     });
    document.getElementById('text_header').innerText=isEnglish ? "Individual software solutions for web, mobile and applications - for private individuals and companies.":"Individuelle Softwarelösungen für Web, Mobil und Anwendungen – für Privatpersonen und Firmen.";
      texts.forEach((id, index) => {
        const element = document.getElementById(id);
        const topic_element = document.getElementById(topics[index]);
        if (element && items[index]) {
          element.innerHTML = isEnglish ? items[index].en_text : items[index].de_text;
        }
        if(topic_element && items[index]){
            topic_element.innerHTML = isEnglish ? items[index].en_topic : items[index].de_topic;
        }
      });
    } else {
      document.getElementById('text1').innerHTML = "Willkommen! Als selbstständiger Softwareentwickler biete ich Ihnen maßgeschneiderte Lösungen in den Bereichen Anwendungssoftware, Webentwicklung und mobile Anwendungen – sowohl für Privatpersonen als auch für Firmen. Mit meiner Erfahrung und Leidenschaft für innovative Technologien entwickle ich passgenaue Software, die Ihren individuellen Anforderungen gerecht wird. Profitieren Sie von hochwertigen und zuverlässigen Produkten, die ich Ihnen als fertige Software bereitstelle.";
    }
  };

  return (
    <div><Sidebar onLanguageChange={LanguageChange}/> 
    <div class="App-header">
    <div class="img_header"><h1 id='topic_header'>FM-Software</h1>
    <p id='text_header'>Individual software solutions for web, mobile and applications - for private individuals and companies.</p><img src="/images/header_2.jpg"></img></div>
      <div class="text_container">
      <div class="text_container_data">
        <h1 id="first_header">Startseite</h1>
          <a id="text1"> </a>
          <div class="services_preview_outercontainer">
          <Link to='/services/application-software' className="services_preview_innercontainer">
              
              <img src="/images/frontpage_1.jpg"></img>
              <div class="inner_text">
                <h2 name="header2" id="e_topic1">Anwendungssoftware</h2>
                <a id="e_text1">
                Wir bieten maßgeschneiderte Anwendungssoftware für Mac und Windows, die speziell auf Ihre Bedürfnisse zugeschnitten ist. Unsere Lösungen sind benutzerfreundlich, leistungsstark und optimieren Ihre Arbeitsabläufe auf beiden Plattformen.</a>
                <h3 name='show-more'>Mehr anzeigen</h3>
              </div>
              </Link>
              <Link to='/services/web-development' className="services_preview_innercontainer">
              <img src="/images/frontpage_2.jpg"></img><div class="inner_text">
              <h2 name="header2" id="e_topic2">Webentwicklung</h2>
              <a id="e_text2">Unsere Full-Stack-Webentwicklung bietet Ihnen eine umfassende Lösung für Ihre Webseite, die sowohl das ansprechende Design der Benutzeroberfläche (Frontend) als auch die leistungsstarke Funktionalität der Serverseite (Backend) umfasst.</a>
              <h3 name='show-more'>Mehr anzeigen</h3>
              </div>
              
              </Link>
              <Link to='/services/mobile-applications' className="services_preview_innercontainer">
              <img src="/images/frontpage_3.jpg"></img><div class="inner_text">
              <h2 name="header2" id="e_topic3">Mobile Anwendungen</h2>
              <a id="e_text3">Wir entwickeln maßgeschneiderte mobile Anwendungen für iOS und Android, die Ihre Anforderungen erfüllen und Ihre Nutzer begeistern. Unsere Apps sind benutzerfreundlich, leistungsstark und optimal auf beide Plattformen abgestimmt.</a>
              <h3 name='show-more'>Mehr anzeigen</h3>
              </div>
              </Link>
          </div>
      </div>
      </div>
    </div>
    </div>
  );
}

export default Frontpage;
