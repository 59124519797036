import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import './services.css';
import { Link } from 'react-router-dom'; 
import Sidebar from '../../Sidebar';
  


function PC_app(){
    const [items, setItems] = useState([]);

    useEffect(() => {
      axios.get('/backend/home.php', {
        headers: {
            'Custom-type': 'service1',
            'Content-Type': 'application/json',  // Beispiel für Content-Type
            // Weitere Header können hier hinzugefügt werden
        }
    })
    .then(response => {
        setItems(response.data);
    })
    .catch(error => {
        console.error('Es gab einen Fehler beim Abrufen der Daten:', error);
    });
    
    }
    , []);// Leer, d.h. dieser Effekt wird nur einmal nach dem initialen Rendern ausgeführt

    useEffect(() => {
        LanguageChange();
      }, [items]);// Dieser Effekt wird nur ausgeführt, wenn sich `items` ändert
    
      const LanguageChange = () => {
        if (items.length > 0) {
          const languageIndex = sessionStorage.getItem("language_index");
          const isEnglish = languageIndex != null && languageIndex == 1;
      
          const texts = ["text1", "e_text1", "e_text2", "e_text3"];
          const topics = ["", "e_topic1", "e_topic2", "e_topic3"];
          document.getElementById('topic_header').innerText= isEnglish ? "Application software" : "Anwendungssoftware";
          document.getElementById('text_header').innerText=isEnglish ? "We offer customized application software solutions that meet your specific digital goals and requirements.":"Wir bieten maßgeschneiderte Anwendungssoftwarelösungen an, die Ihre spezifischen digitalen Ziele und Anforderungen erfüllen.";
          texts.forEach((id, index) => {
            const element = document.getElementById(id);
            const topic_element = document.getElementById(topics[index]);
            if (element && items[index]) {
              element.innerHTML = isEnglish ? items[index].en_text : items[index].de_text;
            }
            if(topic_element){
                topic_element.innerHTML = isEnglish ? items[index].en_topic : items[index].de_topic;
            }
          });
        } else {
          document.getElementById('text1').innerHTML = "Unser Unternehmen spezialisiert sich auf die Entwicklung maßgeschneiderter Anwendungssoftware, die Ihre spezifischen Anforderungen erfüllt und Ihre Geschäftsprozesse optimiert. Mit unserer langjährigen Erfahrung und Expertise gewährleisten wir, dass jede Softwarelösung benutzerfreundlich, leistungsstark und exakt auf Ihre Bedürfnisse abgestimmt ist. Unser Leistungsspektrum umfasst eine Vielzahl von Anwendungen, die Ihnen helfen, Arbeitsabläufe zu erleichtern, eine bessere Organisation zu erreichen und Zeit zu sparen. Hier sind einige Beispiele, wie unsere Softwarelösungen Ihnen helfen können:";
        }
      };
    
    return(
        <div><Sidebar onLanguageChange={LanguageChange}/>
        <div class="App-header">
            <div class="img_header"><h1 id='topic_header'>Anwendungssoftware</h1>
            <p id='text_header'></p>
            <img src="/images/web_app_header.jpg"></img></div>
            <div class="text_container" id="service-container">
            <div class="text_container_data">
                <a id="text1">Loading...</a>
        <div class='enumeration' id='service_enumeration'>
        <div class='enumeration_element' >
            <img src="/images/services_a1.jpg"></img>
            <div class='enumeration_text'> <h4 id="e_topic1">Arbeitsplan- und Schichtplanungssoftware:</h4><a id="e_text1">Loading...</a>
            </div>
            </div>
        <div class='enumeration_element' ><img src="/images/services_a3.jpg"></img><div class='enumeration_text'> <h4 id="e_topic2">Dokumentenmanagement-Systeme:</h4><a id="e_text2">Loading...</a></div></div>
        <div class='enumeration_element' ><img src="/images/services_a2.jpg"></img><div class='enumeration_text'> <h4 id="e_topic3">CRM-Systeme (Customer Relationship Management):</h4><a id="e_text3">Loading...</a></div></div>
      </div>
      </div>
                </div>
            
            </div>
        </div>

    );

    

}

export default PC_app;