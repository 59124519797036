import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';  // Falls du React Router verwenden möchtest
import './sidebar.css';

const Sidebar = ({ onLanguageChange }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const updateLanguageInDOM = () => {
    onLanguageChange();
    if (sessionStorage.getItem("language_index") == null || sessionStorage.getItem("language_index") == 0) {

      document.getElementById('language_selector').src = "/images/england.PNG";
      document.getElementById('h-language_selector').src = "/images/england.PNG";
      document.getElementById('start').innerText = "Startseite";
      document.getElementById('h-start').innerText = "Startseite";
      document.getElementById('products').innerText = "Produkte";
      document.getElementById('h-products').innerText = "Produkte";
      document.getElementById('services').innerText = "Dienstleistungen";
      document.getElementById('h-services').innerText = "Dienstleistungen";
      document.getElementById('service1').innerText = "Anwendungssoftware";
      document.getElementById('h-service1').innerText = "Anwendungssoftware";
      document.getElementById('service2').innerText = "Webentwicklung";
      document.getElementById('h-service2').innerText = "Webentwicklung";
      document.getElementById('last-item').innerText = "Mobile Anwendungen";
      document.getElementById('h-last-item').innerText = "Mobile Anwendungen";
      document.getElementById('contact').innerText = "Kontakt";
      document.getElementById('h-contact').innerText = "Kontakt";
      document.getElementById('about').innerText = "Über mich";
      document.getElementById('h-about').innerText = "Über mich";
     
    } else {
      
      document.getElementById('language_selector').src = "/images/deutschland.PNG";
      document.getElementById('h-language_selector').src = "/images/deutschland.PNG";
      document.getElementById('start').innerText = "Home";
      document.getElementById('h-start').innerText = "Home";
      document.getElementById('products').innerText = "Products";
      document.getElementById('h-products').innerText = "Products";
      document.getElementById('services').innerText = "Services";
      document.getElementById('h-services').innerText = "Services";
      document.getElementById('service1').innerText = "Application software";
      document.getElementById('h-service1').innerText = "Application software";
      document.getElementById('service2').innerText = "Web development";
      document.getElementById('h-service2').innerText = "Web development";
      document.getElementById('last-item').innerText = "Mobile applications";
      document.getElementById('h-last-item').innerText = "Mobile applications";
      document.getElementById('contact').innerText = "Contact";
      document.getElementById('h-contact').innerText = "Contact";
      document.getElementById('about').innerText = "About me";
      document.getElementById('h-about').innerText = "About me";
    }
  };

  const LanguageChange = (i = 0) => {
    if (sessionStorage.getItem("language_index") == null || sessionStorage.getItem("language_index") == 0) {
      if (i === 1) {
        sessionStorage.setItem("language_index", 1);
      }
    } else {
      if (i === 1) {
        sessionStorage.setItem("language_index", 0);
      }
    }
    updateLanguageInDOM();
  };

  useEffect(() => {
    updateLanguageInDOM();
  }, []);

  return (
    <div>
    <div className="topbar">
      <div className="top_elements">
        <div className='left_topbar'>
          <div className="top_element">
            <div className="element_content">
              <img id="logo_sidebar" src="/logo.png" alt="Logo"/>
            </div>
          </div>
        </div>
        <div className='middle_topbar'>
          <div className="top_element">
            <div className="element_content">
              <div className={currentPath === "/" ? "selected" : "normal"}>
              <img className="logo" src="/images/icons/home.png" alt="Home"/>
              <Link to="/" id="start" >Startseite</Link>
            </div>
            </div>
          </div>
          <div className="top_element">
            <div className="element_content">
            <div className={currentPath === "/products" ? "selected" : "normal"}>
              <img className="logo" src="/images/icons/products.png" alt="Products"/>
              <Link to="/products" id="products" >Produkte</Link>
              </div>
            </div>
          </div>
          <div className="top_element">
            <div className="dropdown">
              <div className="element_content">
              <div className={currentPath.startsWith("/services") ? "selected" : "normal"}>
                <img className="logo" src="/images/icons/services.png" alt="Services"/>
                <Link id="services" >Dienstleistungen</Link>
                <img className="logo" src="/images/icons/down-arrow.png" alt="Services"/>
                <div className="dropdown-content">
                  <Link id="service1" to="/services/application-software">Anwendungssoftware</Link>
                  <Link id="service2" to="/services/web-development">Webentwicklung</Link>
                  <Link id="last-item" to="/services/mobile-applications">Mobile Anwendungen</Link>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="top_element">
            <div className="element_content">
            <div className={currentPath.startsWith("/contact") ? "selected" : "normal"}>
            
              <img className="logo" src="/images/icons/contact.png" alt="Contact"/>
              <Link to="/contact" id="contact" >Kontakt</Link>
             
              </div>
            </div>
          </div>
          <div className="top_element">
            <div className="element_content">
              <div className={currentPath === "/about" ? "selected" : "normal"}>
              <img className="logo" src="/images/icons/about.png" alt="About"/>
              <Link to="/about" id="about" >Über mich</Link>
              </div>
            </div>
          </div>
        </div>
        <div className='right_topbar'>
          <div className="top_element">
            <img id="language_selector" src="/images/deutschland.PNG" alt="Language Selector" onClick={() => LanguageChange(1)} />
          </div>
        </div>
      </div>
      </div>
      <div class="topbar-handy">
      <div className="top_element">
            <div className="element_content">
              <img id="logo_sidebar" src="/logo.png" alt="Logo"/>
            </div>
          </div>
      <div className="top_element">
            <img id="h-language_selector" src="/images/deutschland.PNG" alt="Language Selector" onClick={() => LanguageChange(1)} />
          </div> 
      <div class="dropdown-symbol"><img id="DropdownImage"src="/images/icons/menu.png"></img>
      <div class="dropdown-phone">
      <div className="dropdown-phone-content">
          
          <div className="top_element">
            <div className="element_content">
            <div className={currentPath === "/" ? "selected" : "normal"}>
              <img className="logo" src="/images/icons/home.png" alt="Home"/>
              <Link to="/" id="h-start">Startseite</Link>
            </div>
            </div>
          </div>
          <div className="top_element">
            <div className="element_content">
            <div className={currentPath === "/products" ? "selected" : "normal"}>
              <img className="logo" src="/images/icons/products.png" alt="Products"/>
              <Link to="/products" id="h-products">Produkte</Link>
            </div>
            </div>
          </div>

          <div className="top_element">
            <div className="element_content">
            <div className={currentPath === "/contact" ? "selected" : "normal"}>
              <img className="logo" src="/images/icons/contact.png" alt="Contact"/>
              <Link to="/contact" id="h-contact">Kontakt</Link>
            </div>
            </div>
          </div>
          <div className="top_element">
            <div className="element_content">
            <div className={currentPath === "/about" ? "selected" : "normal"}>
              <img className="logo" src="/images/icons/about.png" alt="About"/>
              <Link to="/about" id="h-about" >Über mich</Link>
              </div>
            </div>

          </div>
          <div className="top_element">
            <div className="dropdown">
              <div className="element_content">
              <div className={currentPath.startsWith("/services") ? "selected" : "normal"}>
                <img className="logo" src="/images/icons/services.png" alt="Services"/>
                <Link id="h-services" >Dienstleistungen</Link>
                </div>
                <div className="dropdown-content">
                  <Link id="h-service1" to="/services/application-software">Anwendungssoftware</Link>
                  <Link id="h-service2" to="/services/web-development">Webentwicklung</Link>
                  <Link id="h-last-item" to="/services/mobile-applications">Mobile Anwendungen</Link>
                
              </div>
              </div>
            </div>
          </div>

      </div>
      </div>
      

      </div>
      </div>
    </div>

  );
}

export default Sidebar;
